import { Button, Input, select } from '@material-tailwind/react'
import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'

const UpdateMCQPaperForm = ({ curriculum, setCurriculum, subject, setSubject, year, setYear, paper,
	setPaper, season, setSeason, zone, setZone, name, setname, question,
	setquestion, answer, setanswer, extra, setextra, handleExtraPdf, handleQuestionPdf, handleAnswerPdf, updateMcqPaper, topic, settopic }) => {
	return (
		<div className='mt-[90px]  pl-[100px] pr-[20px]'>
			<h3 className='font-[GilroyBold] text-[1.3rem]' >Update MCQ Paper</h3>
			<div className='bg-white py-5 mt-2 rounded-lg   px-8' >
				<div className='border-[1px] p-5 mb-2 rounded-lg shadow-md border-[#ccc]'>
					<div className='flex justify-betwwen items-center gap-5'>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="">Curriculum</label>
							<select
								onChange={(e) => setCurriculum(e.target.value)}
								value={curriculum}
								label="Choose Your Curriculum"
								className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<optgroup label="CIE" className="text-gray-900 font-semibold">
									<option value="">Curriculum</option>
									<option value="cieigcse">IGCSE</option>
									<option value="ciealevel">A-LEVEL</option>
								</optgroup>
								<optgroup label="IB" className="text-gray-900 font-semibold">
									<option value="Diploma">Diploma</option>
								</optgroup>
								<optgroup label="MALAYSIA" className="text-gray-900 font-semibold">
									<option value="SPM">SPM</option>
								</optgroup>
								{/* More options here... */}
							</select>
						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Subject</label>
							{curriculum === "" && <select
								onChange={(e) => setSubject(e.target.value)}
								id="subject"
								value={subject}
								label="Choose Your Subject Subject"
								className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Curriculum First</option>

							</select>}
							{curriculum === "cieigcse" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									value={subject}
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="0452">Accounting (0452)</option>
									<option value="0610">Biology (0610)</option>
									<option value="0620">Chemistry (0620)</option>
									<option value="0455">Economics (0455)</option>
									<option value="0652">Physical Science (0652)</option>
									<option value="0625">Physics (0625)</option>
									<option value="0653">Science Combined (0653)</option>
									<option value="0654">Science Coordinate (0654)</option>
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									value={subject}
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="9706">Accounting (9706)</option>
									<option value="9700">Biology (9700)</option>
									<option value="9701">Chemistry (9701)</option>
									<option value="9708">Economics (9708)</option>
									<option value="9702">Physics (9702)</option>
								</select>
							}
							{curriculum === "Diploma" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									value={subject}
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="Biology">Biology</option>
									<option value="Chemistry">Chemistry</option>
									<option value="Design Technology">Design Technology</option>
									<option value="Physics">Physics</option>
									<option value="Sports, Exercise & Health Science">Sports, Exercise & Health Science</option>
								</select>
							}
							{curriculum === "SPM" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									value={subject}
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="4551">Biology (4551)</option>
									<option value="4541">Chemistry (4541)</option>
									<option value="4531">Physics (4531)</option>
									<option value="1511">Science (1511)</option>
								</select>
							}
							{curriculum === "Diploma" &&
								<div className='flex justify-center items-center gap-2'>
									<label htmlFor="subject">level</label>
									<select
										onChange={(e) => setSubject(e.target.value)}
										id="subject"
										name="subject"
										className="border-gray-300 w-16   text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
									>
										<option value="All">All</option>
										<option value="HL">HL</option>
										<option value="SL">SL</option>
									</select>
								</div>}
						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Topic</label>
							<select
								onChange={(e) => settopic(e.target.value)}
								label="Choose Your Topic"
								value={topic}
								className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Topic</option>
								<option value="INTERSECTION POINTS">Intersection Points</option>
								<option value="SETS">Sets</option>
								<option value="SURDS INDICES LOG">SURDS INDICES  LOG</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Paper</label>
							<select
								value={paper}
								onChange={(e) => setPaper(e.target.value)}
								label="Choose Your Paper"
								className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Paper</option>
								<option value="paper1">Paper 1</option>
								<option value="paper2">Paper 2</option>
							</select>
						</div>

					</div>
					<div className='grid grid-cols-4 mt-5 '>
						<div className='flex justify-center items-center gap-2 '>
							<label htmlFor="">Year</label>
							<select
								value={year}
								onChange={(e) => setYear(e.target.value)}
								id="curriculum"
								label="Choose You Year"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Year
								</option>
								<option value="2024">2024</option>
								<option value="2023">2023</option>
								<option value="2022">2022</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
								<option value="2018">2018</option>
								<option value="2017">2017</option>
								<option value="2016">2016</option>
								<option value="2015">2015</option>
								<option value="2014">2014</option>
								<option value="2013">2013</option>
								<option value="2012">2012</option>
								<option value="2011">2011</option>
								<option value="2010">2010</option>
								<option value="2009">2009</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Season</label>
							<select
								id="subject"
								value={season}
								onChange={(e) => setSeason(e.target.value)}
								label="Choose Your Season"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Season </option>
								<option value="Summer">Summer</option>
								<option value="Winter">Winter</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Zone</label>
							<select
								onChange={(e) => setZone(e.target.value)}
								label="Choose Your Zone"
								value={zone}
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value=""> Select Your Zone</option>
								<option value="zone1">Zone 1</option>
								<option value="zone2">Zone 2</option>
								<option value="zone3">Zone 3</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 '>
							<label htmlFor="">Name</label>
							<input
								value={name}
								onChange={(e) => setname(e.target.value)}
								placeholder="Enter Name"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								type="text"
							/>
						</div>
					</div>
					<div className='grid grid-cols-2 mt-5 '>
						<div>
							<form
								onSubmit={handleQuestionPdf}
								className='form flex items-start justify-start flex-col my-6 '>
								<FileUploader />
								<Button className='bg-[#3CBBB7] my-6' type='submit' size="md">Question</Button>
								{question && <p className='text-[0.2rem] w-[50px]'>{question}</p>}
							</form>

						</div>

						<div>
							<form
								onSubmit={handleExtraPdf}
								className='form flex items-start justify-start flex-col my-6 '>
								<FileUploader />
								<Button className=' bg-[#3CBBB7] my-6' type='submit' size="md">Extra</Button>
								{extra && <p>{extra}</p>}
							</form>
						</div>
					</div>
				</div>

				<div className='flex items-center justify-end ' >
					<button
						onClick={updateMcqPaper}
						className="bg-[#3CBBB7] font-[GilroyMedium] text-[.8rem] rounded-md py-2 px-8 text-white ">
						Update MCQ  Paper
					</button>
				</div>
			</div>

		</div>
	)
}

export default UpdateMCQPaperForm