import React, { useEffect, useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import PastPaperTop from '../components/PastPaper/PastPaperTop'
import PastPaperList from '../components/PastPaper/PastPaperList'
import { useSelector } from 'react-redux'
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase.config'
const PastPaper = () => {
	// 
	const [selectedPapers, setSelectedPapers] = useState([]);
	const [selectedYears, setSelectedYears] = useState([]);
	const [selectedSeasons, setSelectedSeasons] = useState([]);
	const [selectedZone, setSelectedZone] = useState([]);
	const [curriculum, setCurriculum] = useState("")
	const [subject, setSubject] = useState("")
	//
	const papers = ["paper1", "paper2"];

	const years = ["2023", "2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009"];
	const seasons = ["summer"];
	const zones = ["None Zone"];
	//
	const [paperList, setPaperList] = useState([])
	const [paperID, setPaperID] = useState("")
	const { error, loading, isAuthenticated, users, userProfile } = useSelector(
		(state) => state.user
	)
	const fetchPastPaper = async () => {

		// setFilteredUser([])
		const q = (collection(db, "PastPaper"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setPaperList((prev) => [...prev, {
				id: doc.id,
				...doc.data()
			}])
		})
	}
	const deleteCourse = async (id) => {
		await deleteDoc(doc(db, "PastPaper", id));
		window.location.reload();

	}




	const fetchCurriculum = async () => {
		console.log(selectedPapers)
		console.log("HI")
		console.log(selectedYears)
		console.log(selectedSeasons)
		console.log(selectedZone)
		console.log(paperList)
		setPaperList([])
		const q = query(collection(db, "PastPaper"), where("curriculum", "==", curriculum), where("subject", "==", subject), where("paper", "in", selectedPapers), where("year", "in", selectedYears), where("zone", "in", selectedZone), where("season", "in", selectedSeasons))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setPaperList((prev) => [...prev, {
				id: doc.id,
				...doc.data()
			}])
		})
	}



	console.log(paperList)
	useEffect(() => {
		fetchPastPaper()
	}, [])

	return (
		<div>
			<Topbar />
			<div className='flex lg:gap-6'>
				<Sidebar />
				<div className='flex flex-col '>
					<PastPaperTop
						selectedPapers={selectedPapers} setSelectedPapers={setSelectedPapers} selectedYears={selectedYears} setSelectedYears={setSelectedYears} selectedSeasons={selectedSeasons} setSelectedSeasons={setSelectedSeasons}
						selectedZone={selectedZone} setSelectedZone={setSelectedZone} papers={papers} years={years} seasons={seasons} zones={zones} curriculum={curriculum} setCurriculum={setCurriculum} subject={subject} setSubject={setSubject} fetchCurriculum={fetchCurriculum}
					/>
					<PastPaperList userProfile={userProfile} deleteCourse={deleteCourse} setPaperList={setPaperList} paperList={paperList} />
				</div>
			</div>
		</div>
	)
}

export default PastPaper