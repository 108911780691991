import { Button, Input, select } from '@material-tailwind/react'
import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'

const UpdateTopicalForm = ({ curriculum, setCurriculum, subject, setSubject, year, setYear, paper,
	setPaper, season, setSeason, zone, setZone, name, setname, question,
	setquestion, answer, setanswer, extra, setextra, handleExtraPdf, handleQuestionPdf, handleAnswerPdf, updateTopicalPaper, topic, settopic }) => {
	return (
		<div className='mt-[90px]  pl-[100px] pr-[20px]'>
			<h3 className='font-[GilroyBold] text-[1.3rem]' >Update Topical Paper</h3>
			<div className='bg-white py-5 mt-2 rounded-lg   px-8' >
				<div className='border-[1px] p-5 mb-2 rounded-lg shadow-md border-[#ccc]'>
					<div className='flex justify-betwwen items-center gap-5'>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="">Curriculum</label>
							<select
								onChange={(e) => setCurriculum(e.target.value)}
								value={curriculum}
								label="Choose Your Curriculum"
								className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<optgroup label="CIE" className="text-gray-900 font-semibold">
									<option value="curriculum">Curriculum</option>
									<option value="cieigcse">IGCSE</option>
									<option value="ciealevel">A-LEVEL</option>
								</optgroup>
								<optgroup label="Edexcel" className="text-gray-900 font-semibold">
									<option value="Edexceligcse">IGCSE</option>
									<option value="Edexceligcse(9-1)">IGCSE (9-1)</option>
									<option value="Edexcelalevel">A-LEVEL</option>
								</optgroup>
								<optgroup label="IB" className="text-gray-900 font-semibold">
									<option value="IBDIPLOMA">DIPLOMA</option>
								</optgroup>
								<optgroup label="OCR" className="text-gray-900 font-semibold">
									<option value="OCRGCSE (9-1)">GCSE (9-1)</option>
									<option value="OCRAS">AS</option>
									<option value="OCRA-level">A-LEVEL</option>
								</optgroup>
								<optgroup label="AQA" className="text-gray-900 font-semibold">
									<option value="AQAGCSE">GCSE</option>
									<option value="AQAAS">AS</option>
									<option value="AQAA-level">A-LEVEL</option>
								</optgroup>
								<optgroup label="MALAYSIA" className="text-gray-900 font-semibold">
									<option value="SPM">SPM</option>
								</optgroup>
								{/* More options here... */}
							</select>
						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Subject</label>
							{curriculum === "" && <select
								onChange={(e) => setSubject(e.target.value)}
								value={subject}
								id="subject"
								name="subject"
								className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="1113">Select Curriculum First</option>
							</select>}
							{curriculum === "cieigcse" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="0606">Additional Mathematics(0606)</option>
									<option value="0610">Biology(0610)</option>
									<option value="0620">Chemistry(0620)</option>
									<option value="0455">Economics(0455)</option>
									<option value="0500">English 1st Language(0500)</option>
									<option value="0510">English 2nd Language(0510)</option>
									<option value="0470">History(0470)</option>
									<option value="0417">ICT(0417)</option>
									<option value="0607">Mathematics International(0607)</option>
									<option value="0580">Mathematics(0580)</option>
									<option value="0625">Physics(0625)</option>
									<option value="0653">Science Combined(0653)</option>
									<option value="0654">Science Coordinate(0490)</option>
									<option value="0495">Sociology (0495)</option>
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="9700">Biology(9700)</option>
									<option value="9701">Chemistry(9701)</option>
									<option value="9618">Computer Science (from 2021)(9618)</option>
									<option value="9608">Computer Science(9608)</option>
									<option value="9708">Economics(9708)</option>
									<option value="9231">Further Mathematics (9231)</option>
									<option value="9389">History (9389)</option>
									<option value="9709_mechanics">Mathematics Mechanics (9709)</option>
									<option value="9709_mechanics_1">Mathematics Mechanics 1 (9709)</option>
									<option value="9709_mechanics_2">Mathematics Mechanics 2 (9709)</option>
									<option value="9709_prob_stat_1">Mathematics Probability & Statistics 1 (9709)</option>
									<option value="9709_prob_stat_2">Mathematics Probability & Statistics 2 (9709)</option>
									<option value="9709_pure_1">Mathematics Pure Math 1 (9709)</option>
									<option value="9709_pure_2_3">Mathematics Pure Math 2,3 (9709)</option>
									<option value="9709_stat_1">Mathematics Statistics 1 (9709)</option>
									<option value="9709_stat_2">Mathematics Statistics 2 (9709)</option>
									<option value="9396">Physical Education (9396)</option>
									<option value="9702">Physics (9702)</option>
									<option value="9990">Psychology (from 2018) (9990)</option>
									<option value="9699">Sociology (9699)</option>

								</select>
							}
							{curriculum === "Edexceligcse" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="4BI0">Biology (4BI0)</option>
									<option value="4CH0">Chemistry (4CH0)</option>
									<option value="4PM0">Further Pure Math (4PM0)</option>
									<option value="4MA0">Mathematics A (4MA0)</option>
									<option value="4MB0">Mathematics B (4MB0)</option>
									<option value="4PH0">Physics (4PH0)</option>

								</select>
							}
							{curriculum === "Edexceligcse(9-1)" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="4BI1">Biology 9-1 (4BI1)</option>
									<option value="4CH1">Chemistry 9-1 (4CH1)</option>
									<option value="4PM1">Further Pure Math 9-1 (4PM1)</option>
									<option value="4MA1">Mathematics A 9-1 (4MA1)</option>
									<option value="4MB1">Mathematics B 9-1 (4MB1)</option>
									<option value="4PH1">Physics 9-1 (4PH1)</option>
								</select>
							}
							{curriculum === "Edexcelalevel" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="WBI01">Biology (WBI01)</option>
									<option value="WBI11">Biology (WBI11)</option>
									<option value="WCH01">Chemistry (WCH01)</option>
									<option value="WCH11">Chemistry (WCH11)</option>
									<option value="WET01">English Literature (WET01)</option>
									<option value="WEN01">English (WEN01)</option>
									<option value="WFM01">Further Pure Mathematics (WFM01)</option>
									<option value="WGE01">Geography (WGE01)</option>
									<option value="WDM01">Mathematics D1 (WDM01)</option>
									<option value="WME01">Mathematics M1,M2,M3 (WME01)</option>
									<option value="WMA11">Mathematics Pure (WMA11)</option>
									<option value="WST01">Mathematics S1,S2,S3,S4 (WST01)</option>
									<option value="WMA01/WMA02">Maths Core (WMA01/WMA02)</option>
									<option value="WPH01">Physics (WPH01)</option>
									<option value="WPH11">Physics (WPH11)</option>
									<option value="WPS01">Psychology (WPS01)</option>
								</select>
							}
							{curriculum === "IBDIPLOMA" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="WBI01">Biology (WBI01)</option>
									<option value="WBI11">Biology (WBI11)</option>
									<option value="WCH01">Chemistry (WCH01)</option>
									<option value="WCH11">Chemistry (WCH11)</option>
									<option value="WET01">English Literature (WET01)</option>
									<option value="WEN01">English (WEN01)</option>
									<option value="WFM01">Further Pure Mathematics (WFM01)</option>
									<option value="WGE01">Geography (WGE01)</option>
									<option value="WDM01">Mathematics D1 (WDM01)</option>
									<option value="WME01">Mathematics M1,M2,M3 (WME01)</option>
									<option value="WMA11">Mathematics Pure (WMA11)</option>
									<option value="WST01">Mathematics S1,S2,S3,S4 (WST01)</option>
									<option value="WMA01/WMA02">Maths Core (WMA01/WMA02)</option>
									<option value="WPH01">Physics (WPH01)</option>
									<option value="WPH11">Physics (WPH11)</option>
									<option value="WPS01">Psychology (WPS01)</option>
								</select>
							}
							{curriculum === "OCRGCSE (9-1)" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="J247">Biology A (9-1) - Gateway Science Suite J247</option>
									<option value="J257">Biology B (9-1) - Twenty First Century Science Suite J257</option>
									<option value="J248">Chemistry A (9-1) - Gateway Science Suite J248</option>
									<option value="J258">Chemistry B (9-1) - Twenty First Century Science Suite J258</option>
									<option value="J560">Mathematics (9-1) J560</option>
									<option value="J249">Physics A (9-1) - Gateway Science Suite J249</option>
									<option value="J259">Physics B (9-1) - Twenty First Century Science Suite J259</option>
									<option value="J250">Science A, Combined (9-1) - Gateway Science Suite J250</option>
									<option value="J260">Science B, Combined (9-1) - Twenty First Century Science Suite J260</option>
								</select>
							}
							{curriculum === "OCRAS" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="H020">Biology A (H020)</option>
									<option value="H022">Biology B (Advancing Biology) (H022)</option>
									<option value="H032">Chemistry A (H032)</option>
									<option value="H033">Chemistry B (Salters) (H033)</option>
									<option value="H230">Mathematics A (H230)</option>
									<option value="H235">Mathematics A, Further (H235)</option>
									<option value="H630">Mathematics B (MEI) (H630)</option>
									<option value="H156">Physics A (H156)</option>
									<option value="H157">Physics B (Advancing Physics) (H157)</option>
								</select>
							}
							{curriculum === "OCRA-level" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="H420">Biology A (H420)</option>
									<option value="H422">Biology B (Advancing Biology) (H422)</option>
									<option value="H432">Chemistry A (H432)</option>
									<option value="H433">Chemistry B (Salters) (H433)</option>
									<option value="H240">Mathematics A (H240)</option>
									<option value="H245">Mathematics A, Further (H245)</option>
									<option value="H640">Mathematics B (MEI) (H640)</option>
									<option value="H556">Physics A (H556)</option>
									<option value="H557">Physics B (Advancing Physics) (H557)</option>
								</select>
							}
							{curriculum === "AQAGCSE" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="8461">Biology (8461)</option>
									<option value="8462">Chemistry (8462)</option>
									<option value="8465">Combined Science: Synergy (8465)</option>
									<option value="8464">Combined Science: Trilogy (8464)</option>
									<option value="8300">Mathematics (8300)</option>
									<option value="8463">Physics (8463)</option>
								</select>
							}
							{curriculum === "AQAAS" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="7401">Biology (7401)</option>
									<option value="7404">Chemistry (7404)</option>
									<option value="7366">Further Mathematics (7366)</option>
									<option value="7356">Mathematics (7356)</option>
									<option value="7407">Physics (7407)</option>
								</select>
							}
							{curriculum === "AQAA-level" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="7401">Biology (7401)</option>
									<option value="7404">Chemistry (7404)</option>
									<option value="7366">Further Mathematics (7366)</option>
									<option value="7356">Mathematics (7356)</option>
									<option value="7407">Physics (7407)</option>
								</select>
							}
							{curriculum === "SPM" &&
								<select
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="3472">Additional Mathematics</option>
									<option value="4551">Biology</option>
									<option value="4541">Chemistry</option>
									<option value="1449">Mathematics</option>
									<option value="4531">Physics</option>
									<option value="1511">Science</option>
								</select>
							}

						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Topic</label>
							<select
								onChange={(e) => settopic(e.target.value)}
								label="Choose Your Topic"
								value={topic}
								className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Topic</option>
								<option value="INTERSECTION POINTS">Intersection Points</option>
								<option value="SETS">Sets</option>
								<option value="SURDS INDICES LOG">SURDS INDICES  LOG</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Paper</label>
							<select
								value={paper}
								onChange={(e) => setPaper(e.target.value)}
								label="Choose Your Paper"
								className="border-gray-300 w-[200px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Paper</option>
								<option value="paper1">Paper 1</option>
								<option value="paper2">Paper 2</option>
							</select>
						</div>

					</div>
					<div className='grid grid-cols-4 mt-5 '>
						<div className='flex justify-center items-center gap-2 '>
							<label htmlFor="">Year</label>
							<select
								value={year}
								onChange={(e) => setYear(e.target.value)}
								id="curriculum"
								label="Choose You Year"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Year
								</option>
								<option value="2024">2024</option>
								<option value="2023">2023</option>
								<option value="2022">2022</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
								<option value="2018">2018</option>
								<option value="2017">2017</option>
								<option value="2016">2016</option>
								<option value="2015">2015</option>
								<option value="2014">2014</option>
								<option value="2013">2013</option>
								<option value="2012">2012</option>
								<option value="2011">2011</option>
								<option value="2010">2010</option>
								<option value="2009">2009</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Season</label>
							<select
								id="subject"
								value={season}
								onChange={(e) => setSeason(e.target.value)}
								label="Choose Your Season"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Season </option>
								<option value="Summer">Summer</option>
								<option value="Winter">Winter</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Zone</label>
							<select
								onChange={(e) => setZone(e.target.value)}
								label="Choose Your Zone"
								value={zone}
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value=""> Select Your Zone</option>
								<option value="zone1">Zone 1</option>
								<option value="zone2">Zone 2</option>
								<option value="zone3">Zone 3</option>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 '>
							<label htmlFor="">Name</label>
							<input
								value={name}
								onChange={(e) => setname(e.target.value)}
								placeholder="Enter Name"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								type="text"
							/>
						</div>
					</div>
					<div className='grid grid-cols-3 mt-5 '>
						<div>
							<form
								onSubmit={handleQuestionPdf}
								className='form flex items-start justify-start flex-col my-6 '>
								<FileUploader />
								<Button className='bg-[#3CBBB7] my-6' type='submit' size="md">Question</Button>
								{question && <p className='text-[0.2rem] w-[50px]'>{question}</p>}
							</form>

						</div>
						<div>
							<form
								onSubmit={handleAnswerPdf}
								className='form flex items-start justify-start flex-col my-6 '>
								<FileUploader />
								<Button className='bg-[#3CBBB7] my-6' type='submit' size="md">Answer</Button>
								{answer && <p className='text-[0.2rem] w-[50px]'>{answer}</p>}
							</form>

						</div>
						<div>
							<form
								onSubmit={handleExtraPdf}
								className='form flex items-start justify-start flex-col my-6 '>
								<FileUploader />
								<Button className=' bg-[#3CBBB7] my-6' type='submit' size="md">Extra</Button>
								{extra && <p>{extra}</p>}
							</form>
						</div>
					</div>
				</div>

				<div className='flex items-center justify-end ' >
					<button
						onClick={updateTopicalPaper}
						className="bg-[#3CBBB7] font-[GilroyMedium] text-[.8rem] rounded-md py-2 px-8 text-white ">
						Update Topical  Paper
					</button>
				</div>
			</div>

		</div>
	)
}

export default UpdateTopicalForm