import { Button, Input, select } from '@material-tailwind/react'
import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files';

const CreateMCQPaperform = ({ curriculum, setCurriculum, subject, setSubject, year, setYear, paper,
	setPaper, season, setSeason, zone, setZone, name, setname, question,
	setquestion, answer, setanswer, extra, setextra, handleExtraPdf, handleQuestionPdf, handleAnswerPdf, handleMCQPaper }) => {

	const fileTypes = ["pdf"]


	const papers = ["Select Paper", "paper1"];

	const years = ["Select Year", "2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009"];
	const years2017 = ["Select Year", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009"];
	const seasons = ["Select Season", "Summer", "Winter"];
	const zones = ["Select Zone", "zone1", "zone2", "zone3"];
	const zone2 = ["Select Zone", "zone0", "zone1", "zone2"]

	return (
		<div className='mt-[90px]  pl-[100px] pr-[20px]'>
			<h3 className='font-[GilroyBold] text-[1.3rem]' >Create MCQ Paper</h3>
			<div className='bg-white py-5 mt-2 rounded-lg   px-8' >
				<div className='border-[1px] p-5 mb-2 rounded-lg shadow-md border-[#ccc]'>
					<div className='grid grid-cols-3 '>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="">Curriculum</label>
							<select
								onChange={(e) => setCurriculum(e.target.value)}
								id="curriculum"
								label="Choose Your Curriculum"
								className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<optgroup label="CIE" className="text-gray-900 font-semibold">
									<option value="">Curriculum</option>
									<option value="cieigcse">IGCSE</option>
									<option value="ciealevel">A-LEVEL</option>
								</optgroup>
								<optgroup label="IB" className="text-gray-900 font-semibold">
									<option value="Diploma">Diploma</option>
								</optgroup>
								<optgroup label="MALAYSIA" className="text-gray-900 font-semibold">
									<option value="SPM">SPM</option>
								</optgroup>
							</select>
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Subject</label>
							{curriculum === "" && <select
								onChange={(e) => setSubject(e.target.value)}
								id="subject"
								label="Choose Your Subject Subject"
								className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Curriculum First</option>

							</select>}
							{curriculum === "cieigcse" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="Select Subject"> Select Subject</option>
									<option value="0452">Accounting (0452)</option>
									<option value="0610">Biology (0610)</option>
									<option value="0620">Chemistry (0620)</option>
									<option value="0455">Economics (0455)</option>
									<option value="0652">Physical Science (0652)</option>
									<option value="0625">Physics (0625)</option>
									<option value="0653">Science Combined (0653)</option>
									<option value="0654">Science Coordinate (0654)</option>
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="Select Subject"> Select Subject</option>
									<option value="9706">Accounting (9706)</option>
									<option value="9700">Biology (9700)</option>
									<option value="9701">Chemistry (9701)</option>
									<option value="9708">Economics (9708)</option>
									<option value="9702">Physics (9702)</option>
								</select>
							}
							{curriculum === "Diploma" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="Select Subject"> Select Subject</option>
									<option value="Biology">Biology</option>
									<option value="Chemistry">Chemistry</option>
									<option value="Design Technology">Design Technology</option>
									<option value="Physics">Physics</option>
									<option value="Sports, Exercise & Health Science">Sports, Exercise & Health Science</option>
								</select>
							}
							{curriculum === "SPM" &&
								<select
									onChange={(e) => setSubject(e.target.value)}
									id="subject"
									name="subject"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="Select Subject"> Select Subject</option>
									<option value="4551">Biology (4551)</option>
									<option value="4541">Chemistry (4541)</option>
									<option value="4531">Physics (4531)</option>
									<option value="1511">Science (1511)</option>
								</select>
							}
							{curriculum === "Diploma" &&
								<div className='flex justify-center items-center gap-2'>
									<label htmlFor="subject">level</label>
									<select
										onChange={(e) => setSubject(e.target.value)}
										id="subject"
										name="subject"
										className="border-gray-300 w-16   text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
									>
										<option value="All">All</option>
										<option value="HL">HL</option>
										<option value="SL">SL</option>
									</select>
								</div>}

						</div>
						<div className='flex justify-center items-center gap-2'>
							<label htmlFor="subject">Paper</label>
							{curriculum === "" && <select
								onChange={(e) => setPaper(e.target.value)}
								label="Choose Your Paper"
								className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Curriculum First</option>

							</select>}
							{curriculum === "cieigcse" &&
								<select
									onChange={(e) => setPaper(e.target.value)}
									label="Choose Your Paper"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{papers?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									onChange={(e) => setPaper(e.target.value)}
									label="Choose Your Paper"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{papers?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "Diploma" &&
								<select
									onChange={(e) => setPaper(e.target.value)}
									label="Choose Your Paper"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{papers?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "SPM" &&
								<select
									onChange={(e) => setPaper(e.target.value)}
									label="Choose Your Paper"
									className="border-gray-300 w-[300px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{papers?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}

						</div>
					</div>
					<div className='grid grid-cols-4 mt-5 '>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="">Year</label>
							{curriculum === "" && <select
								onChange={(e) => setYear(e.target.value)}
								id="curriculum"
								label="Choose You Year"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Curriculum First</option>

							</select>}
							{curriculum === "cieigcse" &&
								<select
									onChange={(e) => setYear(e.target.value)}
									id="curriculum"
									label="Choose You Year"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{years2017?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									onChange={(e) => setYear(e.target.value)}
									id="curriculum"
									label="Choose You Year"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{years2017?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "Diploma" &&
								<select
									onChange={(e) => setYear(e.target.value)}
									id="curriculum"
									label="Choose You Year"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{years2017?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "SPM" &&
								<select
									onChange={(e) => setYear(e.target.value)}
									id="curriculum"
									label="Choose You Year"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{years?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Season</label>
							{curriculum === "" && <select
								id="subject"
								onChange={(e) => setSeason(e.target.value)}
								label="Choose Your Season"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Curriculum First</option>

							</select>}
							{curriculum === "cieigcse" &&
								<select
									id="subject"
									onChange={(e) => setSeason(e.target.value)}
									label="Choose Your Season"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{seasons?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									id="subject"
									onChange={(e) => setSeason(e.target.value)}
									label="Choose Your Season"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{seasons?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "Diploma" &&
								<select
									id="subject"
									onChange={(e) => setSeason(e.target.value)}
									label="Choose Your Season"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{seasons?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "SPM" &&
								<select
									id="subject"
									onChange={(e) => setSeason(e.target.value)}
									label="Choose Your Season"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="No season"> No season </option>
								</select>
							}
						</div>
						<div className='flex justify-center items-center gap-2 mr-3'>
							<label htmlFor="subject">Zone</label>
							{curriculum === "" && <select
								onChange={(e) => setZone(e.target.value)}
								label="Choose Your Zone"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
							>
								<option value="">Select Curriculum First</option>

							</select>}
							{curriculum === "cieigcse" &&
								<select
									onChange={(e) => setZone(e.target.value)}
									label="Choose Your Zone"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{zones?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "ciealevel" &&
								<select
									onChange={(e) => setZone(e.target.value)}
									label="Choose Your Zone"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{zones?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "Diploma" &&
								<select
									onChange={(e) => setZone(e.target.value)}
									label="Choose Your Zone"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									{zone2?.map((item) => (
										<option value={item}>{item}</option>
									))}
								</select>
							}
							{curriculum === "SPM" &&
								<select
									onChange={(e) => setZone(e.target.value)}
									label="Choose Your Zone"
									className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								>
									<option value="No Zone"> No Zone</option>
								</select>
							}
						</div>
						<div className='flex justify-center items-center gap-2 '>
							<label htmlFor="">Name</label>
							<input
								value={name}
								onChange={(e) => setname(e.target.value)}
								placeholder="Enter Name"
								className="border-gray-300 w-[400px]  text-[.8rem] rounded-lg border-[1px] bg-transparent outline-none px-3 py-2"
								type="text"
							/>
						</div>
					</div>
					<div className='grid grid-cols-2 mt-5 '>
						<div>
							<form
								onSubmit={handleQuestionPdf}
								className='form flex items-start justify-start flex-col my-6 '>
								{/* <input type='file' /> */}
								<FileUploader types={fileTypes} />
								<Button className='bg-[#3CBBB7] my-6' type='submit' size="md">Question</Button>
								{question && <p className='text-[0.2rem] w-[50px]'>{question}</p>}
							</form>

						</div>

						<div>
							<form onSubmit={handleExtraPdf} className='form flex items-start justify-start flex-col my-6'>
								<FileUploader types={fileTypes} />
								<Button className='bg-[#3CBBB7] my-6' type='submit' size="md">Extra</Button>
								{extra && <p>{extra}</p>}
							</form>
						</div>
					</div>
				</div>

				<div className='flex items-center justify-end ' >
					<button
						onClick={handleMCQPaper}
						className="bg-[#3CBBB7] font-[GilroyMedium] text-[.8rem] rounded-md py-2 px-8 text-white ">
						Create MCQ Paper
					</button>
				</div>
			</div>

		</div>
	)
}

export default CreateMCQPaperform