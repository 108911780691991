import React, { useEffect, useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import { useSelector } from 'react-redux'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import { db } from '../firebase.config'
import MCQTop from '../components/MCQ/MCQTop'
import MCQList from '../components/MCQ/MCQList'
const MCQPaper = () => {
	const [paperList, setPaperList] = useState([])
	const [paperID, setPaperID] = useState("")
	const { error, loading, isAuthenticated, users, userProfile } = useSelector(
		(state) => state.user
	)
	const fetchmcq = async () => {
		// setFilteredUser([])
		const q = (collection(db, "MCQPaper"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setPaperList((prev) => [...prev, {
				id: doc.id,
				...doc.data()
			}])
		})
	}
	const deletemcq = async (id) => {
		await deleteDoc(doc(db, "MCQPaper", id));
		window.location.reload();

	}
	console.log(paperList)
	useEffect(() => {
		fetchmcq()
	}, [])
	return (
		<div>
			<Topbar />
			<div className=''>
				<Sidebar />
				<div className='flex flex-col lg:gap-12 pl-2'>
					<MCQTop />
					<MCQList userProfile={userProfile} deletemcq={deletemcq} setPaperList={setPaperList} paperList={paperList} />
				</div>
			</div>
		</div>
	)
}

export default MCQPaper