import React, { useEffect, useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import TopicalTop from '../components/TopicalPaper/TopicalTop'
import TopicalList from '../components/TopicalPaper/TopicalList'
import { useSelector } from 'react-redux'
import { db } from '../firebase.config'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'

const TopicalPaper = () => {
	const [TopicalPaperlist, setTopicalPaperlist] = useState([])
	const [topicalID, setTopicalID] = useState("")
	const { error, loading, isAuthenticated, users, userProfile } = useSelector(
		(state) => state.user
	)
	const fetchTopicalPaper = async () => {
		// setFilteredUser([])
		const q = (collection(db, "TopicalPaper"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setTopicalPaperlist((prev) => [...prev, {
				id: doc.id,
				...doc.data()
			}])
		})
	}
	const deleteCourse = async (id) => {
		await deleteDoc(doc(db, "TopicalPaper", id));
		window.location.reload();

	}
	console.log(TopicalPaperlist)
	useEffect(() => {
		fetchTopicalPaper()
	}, [])

	return (
		<div>
			<Topbar />
			<div className=''>
				<Sidebar />
				<div className='flex flex-col lg:gap-12 pl-2'>
					<TopicalTop />
					<TopicalList userProfile={userProfile} deleteCourse={deleteCourse} setTopicalPaperlist={setTopicalPaperlist} TopicalPaperlist={TopicalPaperlist} />
				</div>
			</div>
		</div>
	)
}

export default TopicalPaper