import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Sidebar } from '../components/Layout/Sidebar'
import Topbar from '../components/Layout/Topbar'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db, storage } from '../firebase.config'
import {
	ref as storeRef,
	getDownloadURL,
	uploadBytesResumable,
	listAll,
} from "firebase/storage";
import UpdateMCQPaperForm from '../components/MCQ/UpdateMCQPaperForm'



const UpdateMCQPaper = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [curriculum, setCurriculum] = useState("")
	const [subject, setSubject] = useState("")
	const [year, setYear] = useState("")
	const [paper, setPaper] = useState("")
	const [season, setSeason] = useState("")
	const [zone, setZone] = useState("")
	const [name, setname] = useState("")
	const [question, setquestion] = useState("")
	const [answer, setanswer] = useState("")
	const [extra, setExtra] = useState("")
	const [pastpaperDetails, setPastpaperDetails] = useState({})

	const fetchmcq = async () => {
		const docRef = doc(db, "MCQPaper", id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const data = docSnap.data()
			setPastpaperDetails(docSnap.data())
			setCurriculum(data?.curriculum)
			setSubject(data?.subject)
			setYear(data?.year)
			setPaper(data?.paper)
			setSeason(data?.season)
			setZone(data?.zone)
			setname(data?.name)
			setquestion(data?.question)
			setanswer(data?.answer)
			setExtra(data?.extra)
		} else {
			// docSnap.data() will be undefined in this case
			console.log("No such document!");
		}
	}
	useEffect(() => {
		fetchmcq()
	}, [])


	const updateMcqPaper = async () => {
		const date = new Date()
		if (pastpaperDetails.length === 0) {
			alert("Add Course")
		}

		else {
			const repairRef = doc(db, "MCQPaper", id);;
			// Set the "capital" field of the city 'DC'
			await updateDoc(repairRef, {
				curriculum: curriculum,
				subject: subject,
				year: year,
				paper: paper,
				season: season,
				zone: zone,
				name: name,
				question: question,
				answer: answer,
				extra: extra
			});
			navigate("/mcq")
		}
	}

	const handleQuestionPdf = (e) => {

		const file = e.target[0]?.files[0]
		if (!file) return;
		const storageRef = storeRef(storage, `MCQQuestionPaper/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {

			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setquestion(downloadURL)
					console.log(downloadURL)
				});
			}
		);
	}
	const handleAnswerPdf = (e) => {

		const file = e.target[0]?.files[0]
		if (!file) return;
		const storageRef = storeRef(storage, `MCQAnswerPaper/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {

			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setanswer(downloadURL)
					console.log(downloadURL)
				});
			}
		);
	}

	const handleExtraPdf = (e) => {

		const file = e.target[0]?.files[0]
		if (!file) return;
		const storageRef = storeRef(storage, `MCQExtraPaper/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {

			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setExtra(downloadURL)
					console.log(downloadURL)
				});
			}
		);
	}

	return (
		<div>
			<Topbar />
			<div className=''>
				<Sidebar />
				<div className='flex flex-col lg:gap-12 pl-2'>
					<UpdateMCQPaperForm curriculum={curriculum} setCurriculum={setCurriculum} subject={subject} setSubject={setSubject} year={year} setYear={setYear} paper={paper}
						setPaper={setPaper} season={season} setSeason={setSeason} zone={zone} setZone={setZone} name={name} setname={setname} question={question}
						setquestion={setquestion} answer={answer} setanswer={setanswer}
						extra={extra} setextra={setExtra} handleExtraPdf={handleExtraPdf} handleQuestionPdf={handleQuestionPdf} handleAnswerPdf={handleAnswerPdf} updateMcqPaper={updateMcqPaper} />
				</div>
			</div>
		</div>
	)
}

export default UpdateMCQPaper