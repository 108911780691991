import React, { useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import { useNavigate } from 'react-router-dom'
import { db, storage } from '../firebase.config'
import { doc, setDoc } from 'firebase/firestore'
import {
	ref as storeRef,
	getDownloadURL,
	uploadBytesResumable,
	listAll,
} from "firebase/storage";
import CreateMCQPaperform from '../components/CreateMCQPaper/CreateMCQPaperform'
const CreateMCQPaper = () => {
	const [curriculum, setCurriculum] = useState("")
	const [subject, setSubject] = useState("")
	const [year, setYear] = useState("")
	const [paper, setPaper] = useState("")
	const [season, setSeason] = useState("")
	const [zone, setZone] = useState("")
	const [name, setname] = useState("")
	const [question, setquestion] = useState("")
	const [answer, setanswer] = useState("")
	const [extra, setExtra] = useState("")
	const [paperList, setPaperList] = useState([])
	const navigate = useNavigate()
	const handleMCQPaper = async () => {
		if (curriculum.length === "" || subject.length === "" || year.length === "" || paper.length === "" || season.length == "" || zone.length === "" || name.length === "" || question.length === "" || answer.length === "") {
			alert("Please Field All this ")
		} else {
			if (paperList) {
				const paperID = Math.floor(Math.random() * 16 ** 10).toString(16).padStart(10, '0');
				const paperData = {
					curriculum: curriculum,
					subject: subject,
					year: year,
					paper: paper,
					season: season,
					zone: zone,
					name: name,
					question: question,
					answer: answer,
					extra: extra
				}
				await setDoc(doc(db, "MCQPaper", paperID), paperData).then(async (res) => {
					navigate('/mcq')
				}).catch((err) => {
					alert(err.message)
				})
			}
		}
	}

	const handleQuestionPdf = (e) => {

		const file = e.target[0]?.files[0]
		if (!file) return;
		const storageRef = storeRef(storage, `MCQQuestionPaper/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {

			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setquestion(downloadURL)
					console.log(downloadURL)
				});
			}
		);
	}
	const handleAnswerPdf = (e) => {

		const file = e.target[0]?.files[0]
		if (!file) return;
		const storageRef = storeRef(storage, `MCQAnswerPaper/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {

			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setanswer(downloadURL)
					console.log(downloadURL)
				});
			}
		);
	}

	const handleExtraPdf = (e) => {

		const file = e.target[0]?.files[0]
		if (!file) return;
		const storageRef = storeRef(storage, `MCQExtraPaper/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {

			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setExtra(downloadURL)
					console.log(downloadURL)
				});
			}
		);
	}

	return (
		<div>
			<Topbar />
			<div className=''>
				<Sidebar />
				<div className='flex flex-col lg:gap-12 pl-2'>
					<CreateMCQPaperform curriculum={curriculum} setCurriculum={setCurriculum} subject={subject} setSubject={setSubject} year={year} setYear={setYear} paper={paper}
						setPaper={setPaper} season={season} setSeason={setSeason} zone={zone} setZone={setZone} name={name} setname={setname} question={question}
						setquestion={setquestion} answer={answer} setanswer={setanswer}
						extra={extra} setextra={setExtra} handleExtraPdf={handleExtraPdf} handleQuestionPdf={handleQuestionPdf} handleAnswerPdf={handleAnswerPdf} handleMCQPaper={handleMCQPaper} />
				</div>
			</div>
		</div>
	)
}

export default CreateMCQPaper