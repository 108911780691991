
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { useSelector, useDispatch } from "react-redux"
import { useEffect } from 'react';
import { loadUser } from './actions/userActions';
import Login from "./pages/Login"
import CreateUser from './pages/CreateUser';
import Home from './pages/Home';
import PastPaper from './pages/PastPaper';
import TopicalPaper from './pages/TopicalPaper';
import MCQPaper from './pages/MCQPaper';
import CreatePastPaper from './pages/CreatePastPaper';
import CreateTopicalPaper from './pages/CreateTopicalPaper';
import UpdateTopicalPaper from './pages/UpdateTopicalPaper';
import UpdatePastPaper from './pages/UpdatePastPaper';
import CreateMCQPaper from './pages/CreateMCQPaper';
import UpdateMCQPaper from './pages/UpdateMCQPaper';
// import CreateUser from "./pages/CreateUser"
function App() {
  const { error, loading, isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate()
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated && !userProfile) {
      dispatch(loadUser(users));
    }
    if (!isAuthenticated) {
      navigate("/login")
    }

  }, [isAuthenticated])
  return (
    <div className="App">
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/create-user" element={<CreateUser />} />
        <Route exact path="/createpastpaper" element={<CreatePastPaper />} />
        <Route exact path="/createtopicalpaper" element={<CreateTopicalPaper />} />
        <Route exact path="/topicalpaper/:id" element={<UpdateTopicalPaper />} />
        <Route exact path="/" element={<PastPaper />} />
        <Route exact path="/:id" element={<UpdatePastPaper />} />
        <Route exact path="/topicalpaper" element={<TopicalPaper />} />
        <Route exact path="/createmcqpaper" element={<CreateMCQPaper />} />
        <Route exact path="/mcq" element={<MCQPaper />} />
        <Route exact path="/mcq/:id" element={<UpdateMCQPaper />} />
      </Routes>
    </div>
  );
}

export default App;
