import React,{useEffect, useState} from 'react'
import SignupForm from '../components/Login/SignupForm'
import MainLoginImage from "../asset/login.png"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signUp, signUpUsingEmail } from '../actions/userActions'
// import { createUserWithEmailAndPassword } from 'firebase/auth'
// import { auth, db, storage } from '../firebase.config'
// import { doc, setDoc } from 'firebase/firestore'
// import { useNavigate } from 'react-router-dom'
// import { getDownloadURL, uploadBytesResumable,  ref as storageRef } from 'firebase/storage'
// import * as XLSX from 'xlsx';
const CreateUser = () => {
    const navigate = useNavigate("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [designation, setDesignation] = useState("")
    const dispatch = useDispatch()
    const { error, loading, isAuthenticated,users,userProfile } = useSelector(
      (state) => state.user
    );
  const handleRegister = () =>{
    if(name.length===0){
      alert("Please enter your name")
    }
    else if(email.length===0){
      alert("Please enter your Email")
    }
    else if(password.length===0){
      alert("Please enter your password")
    }
    else if(confirmPassword.length===0){
      alert("Please enter your confirm password")
    }
    else if(password !== confirmPassword){
      alert("Password Does Not Match")
    }
    else{
      const dataMain = {
        name: name,
        email: email,
        password: password,
        designation: designation,
      }
      dispatch(signUpUsingEmail(dataMain))
    }
  }
  useEffect(() => {
    if(isAuthenticated){
        navigate("/")
    }
}, [isAuthenticated])

  return (
    <div className='grid grid-cols-3 bg-gradient-to-t from-[#16325B] to-[#227B94] grid-flow-col h-[100vh] ' >
        <SignupForm email={email} handleRegister={handleRegister} designation={designation} setDesignation={setDesignation} setEmail={setEmail} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} showConfirmPassword={showConfirmPassword} setShowConfirmPassword={setShowConfirmPassword} name={name} setName={setName} password={password} setPassword={setPassword} showPassword={showPassword} setShowPassword={setShowPassword} />
        <div className='col-span-2 flex items-center justify-center' >
          <img className='w-[70%]' src={MainLoginImage} />
        </div>
    </div>
  )
}

export default CreateUser