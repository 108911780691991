import React from 'react'
import { Button, Input } from '@material-tailwind/react'
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import { Link } from 'react-router-dom';

const MCQTop = () => {
	return (
		<div className='mt-[100px]  pl-[100px] ' >
			<div className='grid grid-cols-5 items-center grid-flow-col gap-[150px]' >
				<div className='col-span-1' >
					<h3 className='font-[GilroyBold] text-[1.5rem]' >MCQ Paper</h3>
				</div>
				<div className='col-span-2 flex items-center justify-center'>
					<div className="relative flex w-full">
						<Input
							type="search"
							// onChange={filterBySearch}
							// placeholder="Course Name"
							containerProps={{
								className: "min-w-[400px]",
							}}
							className=" !border-t-blue-gray-200 outline-none bg-white pl-9 placeholder:text-blue-gray-300 focus:!border-blue-gray-200"
							labelProps={{
								className: "before:content-none after:content-none",
							}}
						/>
						<div className="!absolute left-3 top-[13px]">
							<CiSearch />
						</div>
					</div>
				</div>

				<div className='col-span-3 flex gap-3 items-center justify-end' >
					<Link to="/createmcqpaper" >  <button className='flex items-center justify-start gap-3 px-4 rounded-lg font-[GilroyMedium] py-2.5 text-white bg-[#3CBBB7]' > <FaPlus /> Create Mcq Paper</button></Link>
					<button onClick={() => window.location.reload()} className='flex items-center justify-start gap-3 font-[GilroyMedium] hover:bg-gray-300 px-4 py-3' ><TfiReload /> Sync Past Paper</button>
				</div>
			</div>
		</div>
	)
}

export default MCQTop