import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import React from 'react'
import { HiDotsCircleHorizontal } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const PastPaperList = ({ paperList, setPaperList, deleteCourse, userProfile }) => {
	return (
		<div className="mt-[20px]  pl-[100px] pr-[20px]">
			<div>
				<div className="bg-white mb-2  border-[1px] border-[#ccc] shadow-sm rounded-md   grid grid-cols-8">

					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem] ">
						<p className=''>Curriculum</p>
					</div>
					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
						<p>Subject
						</p>
					</div>
					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
						<p>Paper
						</p>
					</div>

					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
						<p>Year</p>
					</div>
					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
						<p>Season</p>
					</div>
					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
						<p>Zone</p>
					</div>
					<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
						<p>Name</p>
					</div>
					<div className="font-[GilroyMedium] px-2 py-2  text-[.9rem]  ">
						<p>Action</p>
					</div>
				</div>
				<div className='h-[65vh] overflow-y-scroll'>
					{paperList?.map((item) => (
						<div className="bg-white  border-[1px] border-[#ccc] shadow-sm rounded-md   grid grid-cols-8">

							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem] ">
								<p className=''>{item?.curriculum}</p>
							</div>
							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
								<p>{item?.subject}
								</p>
							</div>
							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
								<p>{item?.paper}
								</p>
							</div>

							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
								<p>{item?.year}</p>
							</div>
							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
								<p>{item?.season}</p>
							</div>
							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
								<p>{item?.zone}</p>
							</div>
							<div className="font-[GilroyMedium] px-2 py-2 border-r-[1px] border-[#ccc] text-[.9rem]  ">
								<p>{item?.name}</p>
							</div>
							<div className="flex items-center justify-start gap-3  ">
								<Menu className=" rounded-none px-0 py-0">
									<MenuHandler>
										<Button className="bg-transparent px-[10px] py-[10px] shadow-none  hover:shadow-none">
											<p className="flex items-center justify-end gap-2">
												<HiDotsCircleHorizontal className="text-black text-[1.3rem]" />{" "}
											</p>
										</Button>
									</MenuHandler>
									<MenuList className="w-[150px] px-0 rounded-none">
										<MenuItem
											onClick={() => deleteCourse(item?.id)}
											className=" rounded-none  px-0 buser-gray-300 ">
											<p className="px-6  text-red-600 font-[InterRegular]">
												Delete Paper
											</p>
										</MenuItem>
										<Link className="outline-none border-none" to={`/${item?.id}`}>
											<MenuItem

												className=" rounded-none  px-0 buser-gray-300 ">
												<p className="px-6  text-black font-[InterRegular]">
													Update Paper
												</p>
											</MenuItem>
										</Link>
									</MenuList>
								</Menu>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default PastPaperList